.myTbl .tab-content {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}

.myTbl td {
    padding: 10px !important;
}

.userd {
    max-Width: 500px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-Radius: 5px;
    box-Shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.userd1 {
    max-Width: 500px;
    margin: auto;
    padding: 20px;
    height: 460px;
    border: 1px solid #ccc;
    border-Radius: 5px;
    box-Shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.userdet {
    background-Color: #FAFAFA;
    padding: 10px;
    border-Radius: 5px;
    box-Shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sp {
    font-Weight: 450;
    color: grey;
}

.input-field {
    width: 80%;
    padding: 10px;
    border: 2px solid rgb(152, 196, 212);
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;
    transition: border-color 0.3s ease-in-out;
    /* Smooth transition for border color */
}

/* Hover styles */
.input-field:hover {
    border-color: blue;
    box-shadow: 0 0 5px rgba(0, 0, 255, 0.5);
}


/* Hover while typing styles */
.hover-while-typing:hover {
    border-color: green;
    /* Additional styles specific to hover while typing */
}

.lbu {
    display: block;
    margin-Bottom: 5px;
    font-Weight: 450;
    color: grey;
}

h5 {
    font-Weight: bold;
}

h3 {
    font-weight: 500;
    margin: auto;
}

.btnc {
    background: #3090C7;
    color: white;
    border: none;
    padding: 10px 20px;
    border-Radius: 5px;
    cursor: pointer;
}

.btnemp {
    background: #3090C7;
    color: white;
    border: none;
    padding: 10px 10px;
    border-Radius: 5px;
    cursor: pointer;
    height: 40px;
    width: 120px;
}

.btnworkreport {
    background: #3e5367;
    color: white;
    border: none;
    padding: 5px 5px;
    border-Radius: 5px;
    cursor: pointer;
    height: 30px;
}

.today-text {
    color: #3e5367;
    font-weight: bold;
}

.FilterbyPeriod {
    display: flex;
    justify-content: space-between;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 10px;
    box-shadow: 5px;
}

.btn-style {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(238, 238, 238);
    color: black;
    border: 2px solid #e7e7e7;
}

.btn-style:hover {
    background-color: #cecbcb;
}

.custom-button {
    width: 100px;
    height: 35px;
    /* background-color: lightgrey; */
    padding-top: 7px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid #87CEEB; /* Add this line for the border */
}
.custom-button-file {
    width: 100px;
    height: 35px;
    /* background-color: lightgrey; */
    padding-top: 7px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid #53666d; /* Add this line for the border */
}
.dropdown-column {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .btn-info {
    background: #17a2b8;
    color: white;
    border: 2px solid #17a2b8;
  }
  
  .btn-info:hover {
    background: #17a2b8;
    color: white;
  }

  .btn-info1 {
    width: 100px;
    height: 40px;
    margin: 10px;
    padding-top: 3px;
    border-radius: 5px;
    background: #17a2b8;
    color: white;
    border: 2px solid #17a2b8;
  }
  
  .btn-info1:hover {
    background: #0c7dd9;
    color: white;
  }

  .profile-container {
    margin: 2rem auto;
    padding: 0; /* Remove unnecessary padding */
  }
  
  .profile-card {
    padding: 1.5rem; /* Uniform padding for the cards */
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 100%; /* Ensure cards take full height of grid cell */
    overflow: hidden; /* Hide overflow to prevent scrollbars */
  }
  
  .personal-details-header {
    text-align: center;
  }
  
  .detail-item {
    margin-bottom: 0.5rem;
  }
  
  .form-group {
    margin-bottom: 1rem; /* Space between form groups */
  }
  
  /* Optional: Adjust the Button for better alignment */
  button {
    margin-top: 1rem;
  }
  
  .userdet {
    background-Color: #FAFAFA;
    padding: 10px;
    border-Radius: 5px;
    box-Shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.sp {
    font-Weight: 450;
    color: grey;
}
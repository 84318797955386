/* Modal Title */
.modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  /* Input and Select */
  .modal-input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    margin-top: 10px;
    color: #333;
  }
  
  .modal-input::placeholder {
    color: #999;
  }
  
  /* Buttons */
  .modal-btn-cancel {
    background-color: #ff4d4d;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .modal-btn-cancel:hover {
    background-color: #e60000;
  }
  
  .modal-btn-add {
    background-color: #5bc0de;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .modal-btn-add:hover {
    background-color: #1d9bd1;
  }
  
  /* Centering the Form */
  form {
    margin: 0 auto;
  }
  
  /* Close Button Styling */
  .modal-header .btn-close {
    font-size: 1.2rem;
    color: #999;
  }
  .custom-container {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Title */
  .custom-title {   
    font-weight: 600;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Select Dropdown Styling */
  .custom-select {
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border 0.3s ease;
  }
  
  .custom-select:focus {
    border-color: #5bc0de;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  /* List Styling */
  .custom-list {
    margin-top: 20px;
  }
  
  .list-group-item {
    background-color: #fff;
    padding: 12px 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .list-group-item:hover {
    background-color: #f1f1f1;
  }
  
  .list-group-item.text-center {
    font-size: 1rem;
    color: #888;
  }
  .tree-node-item {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .tree-node-item:hover {
    background-color: #e9ecef;
  }
  
  /* Button Styling */
  .btn-outline-primary {
    border-color: #007bff;
    color: #007bff;
  }
  
  .btn-outline-primary:hover {
    background-color: #007bff;
    color: white;
  }
  
  .btn-outline-danger {
    border-color: #dc3545;
    color: #dc3545;
  }
  
  .btn-outline-danger:hover {
    background-color: #dc3545;
    color: white;
  }
  
  /* Form Input Styling */
  .custom-field-form .form-control,
  .custom-field-form .form-select {
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 1rem;
  }
  
  /* Modal Button */
  .modal-btn-cancel {
    background-color: #ff5f5f;
  }
  
  .modal-btn-add {
    background-color: #5cb85c;
  }
  
  /* Modal Styling */
  .modal-header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
  }
.form-label {
    color: grey;
    margin: 0;
    padding: 0;
}
.form-control {
    border-radius: 0 !important;
    border: 1px solid #add8e6;
    padding: 0.375rem 0.75rem;
  }

  .form-control:focus {
    border-color: #87cefa;
    box-shadow: 0 0 5px rgba(135, 206, 250, 0.5);
  }

  .form-check-label {
    color: grey;         /* Label text color */
    font-size: 0.875rem;  /* Optional: Adjust font size */
    text-transform: uppercase; /* Optional: Uppercase text for consistency */
  }
  
  /* Styling for the checkbox input */
  .form-check-input {
    border-radius: 0.25rem; /* Optional: Add a slight border-radius */
    border: 1px solid #add8e6;  /* Light blue border for the checkbox */
    background-color: #fff;  /* Set a white background */
    transition: all 0.2s ease-in-out; /* Smooth transition for state changes */
  }
  
  /* Styling when the checkbox is checked */
  .form-check-input:checked {
    background-color: #87cefa; /* Light blue when checked */
    border-color: #87cefa;     /* Light blue border when checked */
  }
/* Wrapper for the report menu */
.report-menu-wrapper {
    padding: 0.1px;
    background-color: #f4f6f8;
    border-radius: 2px;
    font-family: 'Inter', sans-serif;
  }
  
  /* Main heading */
  .report-menu-heading {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  /* Each report section container */
  .report-section {
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
    /* transition: transform 0.2s ease; */
    cursor: pointer;
  }
  
  .report-section:hover {
    /* transform: translateY(-3px); */
  }
  
  /* Main report header styling */
  .report-main {
    display: flex;
    align-items: center;
    padding: 10px 15px;
  }
  
  .report-main-icon {
    font-size: 2rem;
    color: #555;
    margin-right: 15px;
  }
  
  .report-main-text h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  .report-main-text p {
    margin: 4px 0 0;
    font-size: 0.9rem;
    color: #666;
  }
  
  /* Divider line between main and sub-items */
  .divider {
    margin: 0;
    border: none;
    border-top: 1px solid #e0e0e0;
  }
  
  /* Container for sub-report items */
  .report-subitems {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    gap: 15px;
    background: #fafafa;
  }
  
  /* Style for each sub-report item */
  .report-subitem {
    font-size: 0.85rem;
    color: #555;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: background-color 0.2s ease, color 0.2s ease;
    cursor: pointer;
  }
  
  .report-subitem:hover {
    background-color: #1976d2;
    color: #fff;
  }
  
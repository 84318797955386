#root {
  position: relative;
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

hr {
  margin: 15px 0 !important;
  /* border: 0; */
  border-top: 1px solid rgba(224, 224, 224, 1);
  opacity: 1;
}

.myBorder {
  border: 1px solid rgba(224, 224, 224, 1);
}

.hoverText:hover {
  color: #000000 !important;
}

.myBtnRight svg {
  width: 14px;
  stroke: #3e5367;
  color: #3e5367;
}

.myBtnRight {
  display: flex;
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  background-color: #00ffff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.myLabel {
  color: #0000009c;
}

.myInput:focus {
  /* border-bottom: 1.5px solid #000000; */
  box-shadow: 0 0 5px 0px #3e53678a;
}

.myBtn,
.myBtnl:hover {
  text-decoration: underline;
}

.myBtn,
.myBtnl:focus {
  outline: 1.5px solid #0000006b !important;
}

.myBtnForEdit {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  color: #000;
}

.myBtnWorkReport {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  color: #000;
}

.svgBtn svg {
  height: 22px;
  width: 22px;
  color: white;
}

.svgBtn {
  border: none;
  /* border-radius: 10px; */
  padding: 0;
  background-color: transparent;
  outline: none;
}

.myInput[disabled] {
  text-decoration: none;
  border: 1.5px solid #3e53671f;
}

.myBtn[disabled] {
  text-decoration: none;
  border: 1.5px solid #3e53671f;
}

.mySelectBox {
  padding: 10px 16px;
  border: 1.5px solid #3e53678a;
  border-radius: 10px;
  outline: none;
}

.mySelectBox {
  cursor: pointer;
  min-width: 220px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url("./assets/svg/caret-down-fill.svg") no-repeat;
  background-position: calc(100% - 8px) center;
}

.mySelectBox::-ms-expand {
  display: none;
}

.myBtn {
  /* border: none; */
  border: 1.5px solid #d8d6de;

  border-radius: 10px;
  outline: none;
}

.myBtnl {
  /* border: none; */
  border: 1.5px solid #d8d6de;
  height: 40px;
  border-radius: 5px;
  outline: none;
}

.myBtnl:hover {
  border-color: #3e53678a;
  box-shadow: 0 0 5px 0px #3e53678a;
}

.myCheckBox {
  transform: scale(1.2);
}

select.myInput {
  cursor: pointer;
}

.myInput {
  border: none;
  border: 1.5px solid #d8d6de;
  border-radius: 5px;
  padding: 5px 15px;
  outline: none;
  color: #5f5f5f !important;
}

.myInputc {
  border: 1.5px solid #d8d6de;
  border-radius: 5px;
  padding: 5px;
  color: #5f5f5f !important;
  width: 200px;
}

.myInputcn {
  border: 1.5px solid #d8d6de;
  border-radius: 5px;
  padding: 5px 15px;
  color: #5f5f5f !important;
  width: 100px;
}

.myInputl {
  border: none;
  border: 1.5px solid #d8d6de;
  border-radius: 5px;
  padding: 5px 15px;
  outline: none;
  color: #5f5f5f !important;
  height: 43px;
}

.myInputl:hover {
  border-color: #3e53678a;
  box-shadow: 0 0 5px 0px #3e53678a;
}

.myInputl:focus {
  /* border-bottom: 1.5px solid #000000; */
  box-shadow: 0 0 5px 0px #3e53678a;
}

.myImgContainer {
  height: 50px;
  width: 50px;
  background-color: #00000059;
  border-radius: 50%;
  overflow: hidden;
  color: white;
}

.datePicker input {
  border: none;
  border: 1.5px solid #0000006b;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  width: 100%;
}

.myH9 {
  font-size: 0.9rem;
}

.myH7 {
  font-size: 0.7rem;
}

.myTag {
  background-color: #edf1f4 !important;
  border-radius: 5px;
  padding: 3px 5px;
}

.myWhiteTextNoWrap {
  white-space: nowrap;
}

.myActionBtn:hover {
  background-color: white !important;
}

.myActionBtn {
  background-color: #d9e6fb;
  border: none;
  outline: none;
  padding: 5px 5px;
  border-radius: 10px;
}

.myActionBtnicon:hover {
  background-color: white !important;
}

.myActionBtnicon {
  border: none;
  outline: none;
  padding: 5px 5px;
  border-radius: 10px;
}

.tableMenuHover .MuiDataGrid-row {
  position: relative;
  overflow: hidden;
}

.dotHover {
  width: 100%;
}

.dotHoverempicon {
  max-width: 150px;
  margin-right: 15px;
}

.tableMenuHover .MuiDataGrid-cell:hover .expandDiv {
  /* background-color: rgb(204, 203, 203); */
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 80px;
  width: 50px;
  /* width: 300px; */
  position: absolute;
  animation: hoverRight 0.1s ease-out;
}

@keyframes hoverRight {
  0% {
    right: -100px;
    opacity: 0.3;
  }

  100% {
    right: 45px;
    opacity: 1;
  }
}

.expandDiv {
  display: none;
}

.myActionBtn svg {
  height: 18px;
  width: 18px;
}

.myUl {
  margin: 0;
  padding: 0;
  list-style: none;
}

.myCursonPointer {
  cursor: pointer;
}

.myEventStop {
  pointer-events: none;
}

/* swapSelection UI start */
.swapSelection main ul li:hover {
  background-color: #00ffff !important;
}

.swapSelection main ul li.checked {
  background-color: #00ffff !important;
  /* color: rgb(84, 94, 111); */
  /* outline: 1.5px solid #0000006b !important; */
}

.swapSelection main ul li {
  /* background-color: red; */
  cursor: pointer;
  list-style: none;
  padding: 5px 10px;
  border-radius: 5px;

  /* margin-bottom: 2px;
  margin-right: 5px; */
  margin: 10px;
  /* background-color: aliceblue; */
  font-size: 14px;
  background-color: #edf1f44d;
}

.swapSelection main {
  margin: 0;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1.5px solid #0000006b;
  height: 200px;
  width: 100%;
  /* min-width: 250px; */
}

.swapSelection main ul {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  overflow: auto;
  border-radius: 5px;
  height: 100%;
}

.swapSelection .allBtnsMain svg {
  height: 20px;
  width: 20px;
  color: #0000009c;
}

.swapSelection .arrowBtn {
  cursor: pointer;
  border: 1.5px solid #0000006b;
  border-radius: 5px;
  padding: 5px 10px;
}

.swapSelection .arrowBtn.disabledBtn {
  cursor: auto;
  pointer-events: none;
  border-color: #00000026;
}

.swapSelection .arrowBtn.disabledBtn svg {
  color: #00000026 !important;
}

/* swapSelection UI end */
.myTableSection {
  height: 250px;
  overflow: auto;
}

.myDTableCaption {
  display: table-caption;
}

.breadcrumbs {
  max-width: 1200px;
  margin: 10px auto;
}

.breadcrumbs > * {
  display: inline-block;
  margin-right: 0.8px;
}

.breadcrumbs .crumb::after {
  content: url("./assets//svg/chevron-right-new.svg");
  padding-left: 5px;
}

.breadcrumbs .crumb:last-child::after {
  display: none;
}

.breadcrumbs .crumb a {
  color: #282c34;
}

#breadcrumbs a {
  list-style: none;
  text-decoration: none;
  text-transform: capitalize;
  padding-left: 5px;
  font-size: 15px;
}

#breadcrumbs a:hover {
  color: #77aaf7;
}

.crumb-link {
  text-decoration: none;
  color: inherit;
  text-transform: capitalize;
}

.crumb-link:hover {
  color: #77aaf7;
  text-decoration: underline;
}

/********** ManeFacturer Btn*************/
.mfacturerActionBtn {
  background: #fff;
  border: none;
}

.mfacturerActionBtn:hover {
  background: #77aaf7;
  color: #fff;
}

.singleCompText {
  font-size: 10px;
  color: rgb(53, 172, 219);
  text-align: end;
  cursor: pointer;
}

.variant-label {
  font-size: 16px;
  margin-bottom: 5px;
}

.variant-control {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
}

.headingStyles {
  font-size: 20px;
  font-weight: "bolder";
  color: #00a2ff;
}

.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Default styles for your navigation bar */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-container {
  margin-right: 20px;
}

.right-container {
  display: flex;
  align-items: center;
}

.middle-container {
  display: flex;
  align-items: center;
}

.headingStyles {
  margin: 0;
  font-size: 18px;
}

.search-item {
  margin-right: 10px;
  padding: 5px;
  border-radius: 4px;
  background-color: #e5f1f9;
}

.search-input {
  border: none;
  padding: 5px;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .left-container {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .right-container {
    margin-top: 10px;
  }

  .search-item {
    width: 100%;
  }

  .search-input {
    width: 100%;
  }
}

/* .left-container {
  margin-right: 20px;
}

.right-container {
  display: flex;
  align-items: center;
}

.middle-container {
  display: flex;
  align-items: center;
}

.headingStyles {
  margin: 0;
  font-size: 18px;
}

.search-item {
  margin-right: 10px;
  padding: 5px;
  border-radius: 4px;
  background-color: #E5F1F9;
  width: 400px;
}

.search-input {
  border: none;
  padding: 5px;
  border-radius: 4px;
  width: 355px
} */

.enquiry-charts {
  background-color: #55a6d8;
  color: white;
}

.sales-charts {
  background-color: #d89f55;
  color: white;
}

.growth-item {
  position: relative;
  padding: 10px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.growth-vertical1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #d68910;
}

.growth-vertical2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #2471a3;
}

.growth-vertical3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #37af1e;
}

.growth-vertical4::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #2e86c1;
}

.growth-vertical5::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #0accef;
}

.growth-vertical6::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #ffcc00;
}

.bi-search:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.bi-bell:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.bi-envelope:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.impressive-text {
  color: #d68910;
  font-weight: bold;
}

.strength-text {
  color: rgb(109, 108, 108);
  font-weight: bold;
}

.record-text {
  color: #2471a3;
  font-weight: bold;
}

.excellent-text {
  color: #37af1e;
  font-weight: bold;
}

.optimal-text {
  color: #2e86c1;
  font-weight: bold;
}

.branch-text-list {
  text-transform: capitalize;
}

.strong-text {
  color: #0accef;
  font-weight: bold;
}

.available-text {
  color: #ffcc00;
  font-weight: bold;
}

.logoDiv {
  display: flex;
  flex-direction: row;
}

.agency-style {
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  font-style: italic;
  margin-left: 10px;
  margin-top: 5px;
}

.spinner-white {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.userd2 {
  margin: auto;
  padding: 20px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.salpid {
  display: flex;
  justify-content: flex-end;
  /* Align content to the end */
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.salpid1 {
  display: flex;
  justify-content: flex-start;
  /* Align content to the end */
  align-items: center;
  font-weight: bold;
  color: rgb(5, 4, 108);
  font-size: 20px;
}

.userd3 {
  margin: auto;
  padding: 20px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select img {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  display: block;
  z-index: 0;
  pointer-events: none;
}

.custom-select select {
  padding-left: 30px;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}

.clickable-text {
  cursor: pointer; /* Change cursor to pointer */
  background-color: #f0f0f0; /* Light gray background */
  color: #56585a; /* Blue text to indicate interactivity */
  padding: 4px 8px; /* Adjust padding for better click area */
  text-align: center; /* Center the text */
  border: 1px solid #c2c3c4; /* Add a border to match the text color */
  border-radius: 20px; /* Rounded pill shape */
  transition: background-color 0.3s, color 0.3s; /* Smooth hover transition */
}

.clickable-text:hover {
  background-color: #007bff; /* Change background on hover */
  color: #fff; /* White text on hover */
}

.custom-select {
  width: 100%; /* Full-width dropdown */
  padding: 8px 12px; /* Padding for better appearance */
  font-size: 16px; /* Adjust font size for readability */
  border: 1px solid #007bff; /* Border to match the interactive elements */
  border-radius: 4px; /* Rounded corners for a modern look */
  background-color: #f9f9f9; /* Light gray background */
  color: #333; /* Neutral text color */
  outline: none; /* Remove default outline */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
}

.custom-select:focus {
  border-color: #0056b3; /* Darker blue border on focus */
  box-shadow: 0 0 4px rgba(0, 91, 187, 0.5); /* Subtle shadow on focus */
  background-color: #fff; /* White background for focus */
}

.custom-select option {
  background-color: #fff; /* White background for dropdown options */
  color: #333; /* Neutral text color for options */
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container:hover::before .box-contain {
  content: "";
  position: absolute;
  top: 25px;
  right: 38px;
  bottom: 0px;
  border-radius: 5px;
  z-index: -1;
  width: 250px;
  height: 100px;
  margin-right: 10px;
  background-color: rgb(59, 94, 124);
}

.image-container:hover .box-contain {
  display: block;
}

.image-container .box-contain {
  display: none;
  position: absolute;
  background-color: white;
  padding: 10px;
  width: 250px;
  height: 100px;
  margin-right: 5px;
  background-color: rgb(59, 94, 124);
  top: 27px;
  left: -60px;
}

.download-button {
  background-color: #0074cc;
  /* Background color */
  color: white;
  /* Text color */
  border: none;
  /* Remove borders */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  font-size: 16px;
  /* Adjust font size */
  margin: 5px;
  /* Add margin to create space between buttons */
}

/* Hover effect */
.download-button:hover {
  background-color: #005aa3;
  /* Change background color on hover */
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.task-status-1 {
  color: purple;
}

.task-status-2 {
  color: skyblue;
}

.task-status-3 {
  color: orange;
}

.task-status-4 {
  color: red;
}

.task-status-5 {
  color: green;
}

.cursorpointer {
  cursor: pointer;
}

.custom-label {
  font-size: 18px;
  color: #3c90e9;
  margin-right: 10px;
  font-weight: bold;
}

.centered-text-with-modern-style {
  text-align: center;
  font-size: 18px;
  color: white;
  font-style: italic;
  background: linear-gradient(45deg, #ff5733, #ffdd33);
  padding: 6px;
  margin: 8px;
  border-radius: 33px 0px 22px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-container {
  background: linear-gradient(45deg, #2c8bb9, #5ba5c9, #72b2d0, #8abfd8);
}

.custom-datepicker {
  width: 200px;
  /* Adjust the width as needed */
  /* Any other styling you want to apply to the DatePicker */
}

.myBtndeal:hover {
  text-decoration: underline;
}

.myBtndeal:focus {
  outline: 1.5px solid #0000006b !important;
}

.myBtndeal {
  background-color: #08718e;
  color: white;
}

.myBtndeal {
  border: 1.5px solid #0000006b;
  border-radius: 10px;
  outline: none;
}

.myBtnMsg:hover {
  text-decoration: underline;
}
.myBtnMsg:focus {
  outline: 1.5px solid #0000006b !important;
}

.myBtnMsg {
  background-color: #08718e;
  color: white;
  border: 1.5px solid #0000006b;
  border-radius: 10px;
  outline: none;
  height: 35px;
  width: 200px;
}

.main1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container1 {
    display: flex;
  }
  
  .left {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa; /* Light gray background */
  }
  
  .right {
    border: 1.5px solid #FF8888;
    padding: 10px;
    margin-left: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa; /* Light gray background */
    transition: border-color 0.3s ease; /* Added transition for smoother color change */
  }
  
  .right.drag-over {
    border-color: #7FD37F; /* Change the border color to light blue when dragging over */
    border-width: 1.5px; /* Increase the border width */
  }
  
  .box {
    width: 100px;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: move;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .dropped-item {
    width: 100px;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .dropped-itemsaved {
  width: 100px;
  background-color: #a4e196;
  margin-bottom: 10px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .drop-message {
    text-align: center;
    padding-top: 10px;
    color: #7FD37F;
  }
  
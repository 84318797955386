/* Filter Card Styles */
.filter-card {
  background: #f9f9f9;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Filter Form Layout */
.filter-form {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: flex-end;
}

/* Each filter group */
.filter-group {
  display: flex;
  flex-direction: column;
  flex: 1 1 180px;
}

.filter-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.filter-group select,
.filter-group input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

/* Filter Button */
.filter-button {
  background-color: #3172b9;
  color: #fff;
  border: none;
  padding: 2px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #0056b3;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

/* Container for sub-report items */
.report-subitems {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px;
  gap: 15px;
  background: #fafafa;
}

/* Style for each sub-report item */
.report-subitem {
  font-size: 0.85rem;
  color: #555;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
  cursor: pointer;
}

body {
    background-color: #eaeff1;
    font-family: 'Roboto', sans-serif;
  }
  
  .MuiPaper-root {
    transition: box-shadow 0.3s;
  }
  
  .MuiPaper-root:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  }
  
  .MuiButton-containedPrimary {
    background-color: #1976d2;
  }
  
  .MuiButton-containedPrimary:hover {
    background-color: #1565c0;
  }
  
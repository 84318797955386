.navbarDiv a {
    color: #7299b6;
}

.navbarDiv {
    /* background-color: #f8f9fa; */
    background-color: white;
    color: #7299b6;
    height: 85px;
    z-index: 1000;
    border-bottom: 1px solid #edf1f4;
    /* box-shadow: 0px 0px 20px #01297040; */

}